@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Hanken+Grotesk:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:opsz,wght@12..96,200..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rethink+Sans:ital,wght@0,400..800;1,400..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Rethink+Sans:ital,wght@0,400..800;1,400..800&display=swap');

/*blog font*/
@import url('https://fonts.googleapis.com/css2?family=BIZ+UDPMincho&display=swap');

body {
  margin: 0;
  font-family: "Rethink Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


h2 {
  font-family: "Rethink Sans";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ql-editor {
  font-family: "Rethink Sans", serif !important;
  color: #b7a78b !important;
  font-size: 13px !important;
  padding: 16px !important;
}

/*loader*/
.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #000;
  z-index: 9999;
  overflow: hidden;
  padding: 1rem;
}

.text-container {
  display: flex; /* Ensure text and moving text are on the same line */
  align-items: center; /* Vertically center the text */
  gap: 1rem; /* Space between 'Sope' and moving text */
}

.preloader-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
}

.preloader-text {
  font-size: 2rem;
  font-weight: bold;
  color: #fff;
}

.moving-text {
  position: relative;
  height: 2rem; /* Adjust this based on the height of your text */
  width: 250px;
  overflow: hidden;
}

.text {
  position: absolute;
  width: max-content;
  opacity: 0;
  animation: moveText 3s infinite;
  font-size: 2rem;
  font-weight: bold;
  color: #fff;
}

@media (max-width: 640px) {
  .preloader-content {
    flex-direction: column;
    gap: 1rem;
  }

  .text-container {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding-left: 45px;
  }

}


.text:nth-child(1) {
  animation-delay: 0s;
}

.text:nth-child(2) {
  animation-delay: 1s;
}

.text:nth-child(3) {
  animation-delay: 2s;
}

@keyframes moveText {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  30% {
    transform: translateY(0%);
  }
  70% {
    opacity: 1;
  }
  90% {
    opacity: 0;
  }
  100% {
    transform: translateY(-100%);
    opacity: 0;
  }
}





/* Custom Scrollbar */
.custom-scrollbar::-webkit-scrollbar {
  width: 8px;               /* Width of the scrollbar */
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #000; /*  #f1f1f1;        Track color */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #888;     /* Scrollbar color */
  border-radius: 10px;        /* Scrollbar rounded corners */
  /*border: 3px solid #f1f1f1;   Creates padding around the thumb */
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;           /* Hover color */
}


/* swiper slider */
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}



/*slider*/
